<template>
  <div class="card card-body shadow-sm infomations-list">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.MENU_STUDENT_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">
          <div class="col-xl-6 col-lg-12 col-sm-12">
            <div class="form-group">
              <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.name.label }}</label>
              <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                <app-input :name="filters.name.name" input-style="normal"
                           v-model="filters.name.value"
                />
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-12 col-sm-12">
            <div class="form-group">
              <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.sell_date.label }}</label>
              <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                <app-date-range-picker
                      :rules="`from_date_list_page:${filters.sell_date.value}`"
                      :name="filters.sell_date.name"
                      v-model="filters.sell_date.value"
                      timePicker24Hour="false"
                      format="YYYY-MM-DD"
                      @input="filters.sell_date.value = $event"
                    />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common_vn.create") }}
        </button>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common_vn.edit") }}
          </button>
        </td>
      </template>

      <template v-slot:body-cell-image_path="props">
        <td class="app-align-middle" style="display: flex;justify-content: center;">
          <img :src="props.row.image.url" v-if="props.row.image.path" style="width: 100px; height: 100px">
        </td>
      </template>
    </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {INFORMATION_CONSTANT} from "../../../constants/information";
  export default {
    name: "InformationList",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          name: {
            name: "name",
            condition: "like",
            label: this.$t("menu_student.name")
          },
          sell_date : {
            name: "sell_date",
            condition: "equal",
            label: this.$t("menu_student.sell_date"),
          },
        },
        columns: [
          {name: "name", label: this.$t("menu_student.name"), sortable: true, textAlign: 'text-center'},
          {name: "name_vn", label: this.$t("menu_student.name"), sortable: true, textAlign: 'text-center'},
          // {name: "price_s", label: this.$t("menu_student.price_s"), sortable: true, textAlign: 'text-center'},
          // {name: "price_m", label: this.$t("menu_student.price_m"), sortable: true, textAlign: 'text-center'},
          // {name: "price_l", label: this.$t("menu_student.price_l"), sortable: true, textAlign: 'text-center'},
          {name: "image_path", label: this.$t("menu_student.image"), textAlign: 'text-center'},
          {name: "sell_date", label: this.$t("menu_student.sell_date"), sortable: true, textAlign: 'text-center'},
          {name: "edit", label: this.$t("common_vn.action"), textAlign: 'text-center'},
        ],
        meta: [],
        endPoint: ''
      };
    },
    watch : {
      "filters.from_date_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
      "filters.to_date_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
    },
    methods: {
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.MENU_STUDENT_CREATE})
      },
      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.MENU_STUDENT_EDIT, params: {id: entry.id}})
      },
      handlerApartmentNameClick(entry) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}});
        window.open(routeApartment.href, '_blank');
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {
            'filters.infor_type.equal' : 'all',
            'filters.status_publishing.equal' : 'all'
          }
        })
      },
      onSearch() {
        // this.getTourSelectName();
      },
      
      getInformationType(typeID) {
        let type = INFORMATION_CONSTANT.types.find(({id}) => id === typeID);
        if (type == undefined) {
          return '';
        }
        return type.name;
      },
      getStatusPublising(typeID) {
        let type = INFORMATION_CONSTANT.status.find(({id}) => id === typeID);
        if (type == undefined) {
          return '';
        }
        return type.name;
      },
    },
    mounted: function () {
      this.$request.get(this.ENDPOINT.MENU_STUDENT_LIST).then(res => {
        this.meta = {
          data: res.data,
        }
      })
    },
  }
</script>
